import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import comicGreenBG from "../images/comic-green-background-small.svg"
import comicBlueBG from "../images/comic-blue-background-small.svg"
// import Img from 'gatsby-image'

const BlogPosts = ({data}) => {
    const {blogdata, homepagedata} = useStaticQuery(graphql`
        query {
            blogdata: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(blog)/.*[.]md$/"}}, 
                sort: {fields: [frontmatter___date], order: DESC}, 
                limit: 3
            ) {
            edges {
                node {
                fields {
                    slug
                }
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    title
                    featureimagealt
                    featureimage {
                        childImageSharp {
                            fluid(maxWidth: 1600, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                        extension
                        publicURL
                    }
                }
                }
            }
            }
            homepagedata: file(relativePath: {eq: "settings/settings-home.md"}) {
                childMarkdownRemark {
                    frontmatter {
                        allnewstext
                    }
                }
            }
        }
        `
    )
    const postsfull = blogdata
    const pageData = homepagedata.childMarkdownRemark.frontmatter
    return (
        <>
                <div id="news" className="anchorid"></div>
                <section className="container mx-auto text-center">
                    <h3 className="heading-box-text3 text-3xl md:text-4xl lg:text-5xl p-4 mx-8">
                        <span>
                            Latest News You Can Use...
                        </span>
                    </h3>
                    <article className="comic">
                        {postsfull.edges.map((edge, index) => {
                            const { frontmatter, fields } = edge.node
                            const alternateBG = index %2 === 0
                            let comicBG
                            alternateBG? comicBG = comicBlueBG : comicBG = comicGreenBG
                            return (
                                <div key={edge.node.id} className="w-full">
                                    <Link to={fields.slug}>
                                    <div className="panel pulserev" style={{backgroundImage: "url(" + comicBG + ")", backgroundSize: "cover", overflow: "hidden",}}>
                                        <p className="text top-left text-lg md:text-xl z-20">
                                            {frontmatter.date}
                                        </p>
                                        <div className="flex-auto flex justify-between flex-col lg:flex-row">
                                            {/* <div className="flex items-center px-3 py-3 lg:pr-0 lg:w-1/3">
                                                {frontmatter.featureimage.extension === 'svg'?
                                                <img className="flex-1 border-white border-2 shadow-lg" src={frontmatter.featureimage.publicURL} alt={frontmatter.featureimagealt} />
                                                :
                                                <Img className="flex-1 border-white border-2 shadow-lg" fluid={frontmatter.featureimage.childImageSharp.fluid} alt={frontmatter.featureimagealt} />
                                                }
                                            </div> */}
                                            <div className="flex text-left">
                                                <div className="flex-1 speech text-base sm:text-xl 2xl:text-2xl pb-6">
                                                    <h2 className="text-xl sm:text-2xl 2xl:text-4xl py-3 font-mono">{frontmatter.title}</h2>
                                                    <p>{edge.node.excerpt}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </article>
                    <h3 className="heading-box-text2 text-3xl md:text-4xl lg:text-5xl p-4 mx-8 pt-8">
                        <Link to="/blog/" itemProp="url">
                            <span className="pulse">
                                {pageData.allnewstext}
                            </span>
                        </Link>
                    </h3>
                </section>
        </>
    )
}

export default BlogPosts