// import React, {useState} from 'react'
// import addToMailchimp from 'gatsby-plugin-mailchimp'
import React from "react";
import comicBlueBG from "../images/comic-green-background-small.svg";

const SubscribeForm = ({formid}) => {
    // const [email, setEmail] = useState('')
    // const [message, setMessage] = useState('')

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const result = await addToMailchimp(email)
    //     setMessage(result.msg)
    // }
    // const handleChange = event => {
    //     setEmail(event.target.value)
    //   }
    return (
        <>
            <div className="comic justify-center noprint-section">
                <div className="panel rounded-lg w-full sm:w-3/4 lg:w-full my-3" style={{backgroundImage: "url(" + comicBlueBG + ")", backgroundSize: "cover", overflow: "hidden",}}>
                {/* <form name="subscribeForm" method="POST" id={formid} className="" onSubmit={handleSubmit}> */}
                <form name="subscribeForm" method="POST" id={formid} className="" data-netlify="true">
                    <input type="hidden" name="form-name" value="subscribeForm" />
                    <h3 className="pulse heading-box-text1 text-xl md:text-2xl pt-3 mx-4">
                        <span>Subscribe to our Newsletter</span>
                    </h3>
                    <div className="pt-3 pb-3 bg-white m-4 rounded">
                    {/* <div className="message text-sm" dangerouslySetInnerHTML={{ __html: message}} /> */}
                    <div className="text-xl mb-4">
                        <label>
                            <span className="font-mono text-gray-700">Email address</span>
                            <br />
                            <input
                                className="text-base py-2 px-1 w-9/12 rounded mt-3 leading-tight text-gray-700 border shadow appearance-none focus:outline-none focus:shadow-outline"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter Email Address..."
                                // onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <button className="font-cursive px-4 py-1 text-xl text-white tracking-wider pulse bg-red-700 rounded-full hover:bg-red-500 focus:outline-none focus:shadow-outline" type="submit">
                        Subscribe
                    </button>
                    </div>
                </form>
                </div>
            </div>
        </>
    )
}

export default SubscribeForm