import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { ImHome } from "react-icons/im"
import { IoRocketOutline } from "react-icons/io5"
import SubscribeForm from "../components/SubscribeForm"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import BlogPosts from "../components/BlogPosts"
 
const Success = ({location}) => {
    const {spaceManOnRokitWithPhone} = useStaticQuery(graphql`
    query {
        spaceManOnRokitWithPhone: file(relativePath: {eq: "mobile-first-approach-website-design.png"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
        }
    }
`)
    return (
        <>
            <Layout location={location}>
                <SEO />{/* title, description, image, article */}
                <PageHero heading="Successfully Submitted" />
                <section className="container mx-auto max-w-7xl text-center pt-4">
                  <div className="px-8 pt-4">
                        <ul className="text-left font-mono text-xs md:text-base">
                          <li className="inline-block border-black border-r-2">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-2">Home</span>
                            </Link>
                          </li>
                          <li className="inline-block">
                            <span className="px-2">Successfully Submitted</span>
                          </li>
                        </ul>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <div className="p-6 lg:w-2/3">
                            <h2 className="text-2xl sm:text-4xl md:text-5xl text-left py-3 font-mono">Successfully Submitted</h2>
                            <h3 className="text-xl sm:text-3xl md:text-4xl text-left py-2 font-mono">Thank you, we have received your form submission.</h3>
                            <Img className="bounce flex-1 max-h-48" fluid={spaceManOnRokitWithPhone.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} alt="Thank you, we have received your form submission" title="Thank you, we have received your form submission" />
                            <p className="text-xl sm:text-2xl font-light text-left py-3">You may also be interested in reading some of the latest articles from our blog…</p>
                        </div>
                        <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 hidden lg:flex lg:flex-col items-start">
                            <div className="lg:sticky top-24">
                                <div className="text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                                    <div className="inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                                </div>
                                <p className="text-xs lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                                <p className="text-xs lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                                <div className="hidden lg:inline-block">
                                    <SubscribeForm formid="subscribe-form-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlogPosts />
                </section>
            </Layout>
        </>
    )
}

export default Success